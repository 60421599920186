import Paragraph from "@components/Paragraph";
import SVG from "@components/SVG";
import Title from "@components/Title";
import BackgroundImage from "gatsby-background-image";
import { IConfig } from "gatsby-node";
import {
  GatsbyImage,
  getImage,
  ImageDataLike,
  StaticImage,
} from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React, {
  BaseSyntheticEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import { useSwipeable } from "react-swipeable";
import { graphql, useStaticQuery } from "gatsby";
import VideoCarouselItem from "./components/VideoCarouselItem";

interface IVideoCarouselProps {
  blade: {
    videos: {
      isInitialVideo: boolean;
      description: string;
      video: {
        thumbnailUrl: string;
        providerUid: string;
        url: string;
      };
      thumbnailImage: any;
    }[]; // TODO types
    heading: string;
    videoFrame: ImageDataLike;
  };
  config: IConfig;
}

const getWidth = () => {
  if (typeof document !== `undefined`) {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }
};

function useCurrentWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    // let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      // clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      // timeoutId = setTimeout(() => setWidth(getWidth()), 150);
      setWidth(getWidth());
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return width;
}

const VideoCarousel: React.FC<IVideoCarouselProps> = ({
  blade: { videos, heading, videoFrame },
  config,
}) => {
  // TODO types
  const [currentVideo, setCurrentVideo] = useState<any>(
    videos.find((video) => video.isInitialVideo)
  );
  // TODO types
  const [translateVal, setTranslateVal] = useState<number>(0);
  const [initialX, setInitialX] = useState<number>(0);
  const [wrapperWidth, setWrapperWidth] = useState<any>(0);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [paused, setPaused] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const frame = convertToBgImage(getImage(videoFrame));
  const _videos = [...videos];
  const currentWidth = useCurrentWidth();

  useLayoutEffect(() => {
    if (typeof document !== `undefined`) {
      const firstVidEl = document.getElementById("firstVid");
      if (firstVidEl && firstVidEl.getBoundingClientRect()) {
        const widthbound = firstVidEl.getBoundingClientRect();
        const newWrapperWidth =
          currentWidth &&
          (widthbound.width / 1.35) * _videos.length - currentWidth;

        if (newWrapperWidth && -translateVal >= newWrapperWidth) {
          -newWrapperWidth;
        } else {
          setWrapperWidth(newWrapperWidth);
        }
      }
    }
  }, [setWrapperWidth, currentWidth]);

  useEffect(() => {
    let interval;
    if (typeof document !== `undefined`) {
      const effectFirstVidEl = document.getElementById("firstVid");
      if (effectFirstVidEl && effectFirstVidEl.getBoundingClientRect()) {
        const widthbound = effectFirstVidEl.getBoundingClientRect();
        const newWrapperWidth =
          currentWidth &&
          (widthbound.width / 1.35) * _videos.length - currentWidth;

        interval = setInterval(() => {
          setIsAnimating(false);
          if (!paused) {
            setIsAnimating(true);
            if (newWrapperWidth && -translateVal >= newWrapperWidth) {
              setTranslateVal(0);
            } else if (
              newWrapperWidth &&
              newWrapperWidth - widthbound.width / 1.35 < -translateVal
            ) {
              setTranslateVal(-newWrapperWidth);
            } else {
              setTranslateVal(translateVal - widthbound.width / 1.35);
            }
          }
        }, 5000);
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handleSwipeVideos = (deltaX: any) => {
    if (initialX + deltaX > 0) return setTranslateVal(0);
    if (initialX + deltaX < -wrapperWidth) {
      return setTranslateVal(-wrapperWidth);
    }
    return setTranslateVal(initialX + deltaX);
  };

  const handlers = useSwipeable({
    onTouchStartOrOnMouseDown: (eData) => setInitialX(translateVal),
    onSwiping: (eventData) => handleSwipeVideos(eventData.deltaX),
    trackMouse: true,
    ...config,
  });

  const { playIcon } = useStaticQuery(graphql`
    query getPlayIcon {
      playIcon: file(relativePath: { eq: "icn-play.png" }) {
        publicURL
        absolutePath
        relativePath
      }
    }
  `);

  return (
    <section className="flex flex-col bg-richBlack py-12 md:py-20 pb-0 md:pb-0 overflow-x-hidden overflow-y-hidden">
      <div className="flex flex-col md:flex-row md:justify-between px-4 sm:px-8 container mx-auto">
        <div className="w-full md:w-[30%]">
          <Title
            {...{
              color: "white",
              underlined: true,
              className: "",
              type: 2,
              bgGlow: true,
            }}
          >
            {heading}
          </Title>
          <Paragraph {...{ className: "mb-6", color: "slateGrey" }}>
            {currentVideo.description}
          </Paragraph>
        </div>
        {frame ? (
          <div className="relative pt-[51.12%] md:pt-[31.12%] lg:pt-[29.12%] w-full  md:w-[57%]">
            <BackgroundImage
              {...frame}
              className="w-full h-full absolute bg-contain bg-center bg-norepeat top-0 left-0 z-20 pointer-events-none select-none"
              style={{ position: "absolute" }}
            />
            <iframe
              src={`https://www.youtube.com/embed/${currentVideo.video.providerUid}?modestbranding=1&rel=0&playsinline=0&allowfullscreen=true`}
              frameBorder="0"
              allowFullScreen={true}
              className="w-[97.2%] h-[97%] md:h-[85%] lg:h-[97%] absolute top-[1.5%] md:top-[7.5%] lg:top-[1.5%] left-[1.4%] rounded-2xl z-10"
            />
          </div>
        ) : null}
      </div>
      <div
        className="mt-20 z-40 min-h-[150px] h-[150px] max-h-[400px] relative"
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
        {...handlers}
      >
        <div
          className={`flex justify-start h-full w-full !select-none overflow-visible  ${
            !paused ? " duration-[3000ms]" : "duration-0"
          }`}
          style={{ transform: `translateX(${translateVal}px)` }}
          ref={ref}
          id="videosWrapper"
        >
          {_videos.map((video, index) => (
            <VideoCarouselItem
              key={"videocarousel-" + index}
              index={index}
              thumbnailImage={video.thumbnailImage}
              videosLength={_videos.length}
              onClick={() => {
                setCurrentVideo(video);
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default VideoCarousel;
