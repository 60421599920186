import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";

import Gemstone, { IGemstone } from "@components/Gemstone";
import WithIsVisible from "@components/WithIsVisible";

import "./style.scss";
import { useInView } from "react-intersection-observer";

interface ICardProps {
  rightAlign: boolean;
  topAlign: boolean;
  heading: string;
  body: string;
  card: {
    background: IGatsbyImageData;
    foreground: IGatsbyImageData;
    foregroundPosition: any; // TODO types
    animation: string;
  };
  background: IGatsbyImageData;
}

const Card: React.FC<ICardProps> = ({
  rightAlign,
  topAlign,
  heading,
  body,
  card,
  background,
}) => {
  const {
    background: cardBackground,
    foreground: cardForeground,
    foregroundPosition,
    animation,
  } = card;

  const bg = convertToBgImage(getImage(background));
  const cardBg = getImage(cardBackground);
  const cardFg = getImage(cardForeground);

  const isAnimated = animation?.length > 0 && animation !== "None";
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.7,
    triggerOnce: true,
  });
  return (
    <div className="relative" ref={ref}>
      {bg ? (
        <div className="w-full pt-[100%] absolute -top-20 lg:-top-32 left-0">
          <BackgroundImage
            {...bg}
            className="w-full h-full absolute top-0 left-0 bg-top bg-norepeat bg-contain z-0"
            style={{ position: "absolute" }}
          />
        </div>
      ) : null}

      <div className="container mx-auto px-4 sm:px-8">
        <div
          className={`flex items-center ${
            !rightAlign
              ? "flex-col-reverse md:flex-row-reverse"
              : "flex-col md:flex-row"
          } ${topAlign ? "md:items-end" : "md:items-start"} -mx-8`}
        >
          <div
            className={`md:flex w-full md:w-1/2 px-8 relative z-30 ${
              rightAlign ? "md:justify-end md:text-right" : "md:text-left"
            }`}
          >
            <div className="max-w-lg mx-auto md:mx-0 text-center md:text-left">
              {heading ? (
                <h2
                  className={`${
                    inView ? `text-fade-in-glow` : "opacity-0"
                  } mb-8 md:mb-12 text-3xl md:text-4xl lg:text-5xl font-display uppercase text-white`}
                >
                  {heading}
                </h2>
              ) : null}

              {body ? (
                <p
                  className={`${
                    inView ? `text-fade-in-glow__delayed` : "opacity-0"
                  } mt-8 md:mt-12 text-lg text-white text-opacity-50`}
                >
                  {body}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-1/2 p-8 relative text-center">
            <WithIsVisible
              children={({ isVisible }: { isVisible: boolean }) => (
                <div
                  className={`cards-with-text__card-animation ${animation?.toLowerCase()} block w-96 max-w-full md:max-w-[80%] mx-auto md:mx-0 ${
                    isAnimated ? "group" : ""
                  } relative md:absolute md:left-1/2 ${
                    topAlign
                      ? "-mt-56 md:mt-0 md:bottom-0"
                      : "-mb-56 md:mb-0 md:top-0"
                  } transform md:-translate-x-1/2 z-30`}
                >
                  <div className="cards-with-text__card-inner w-full transition-all duration-1000">
                    <span
                      className={`block w-[95%] h-[95%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white ${
                        isVisible
                          ? "opacity-100 visible scale-90"
                          : "opacity-0 invisible scale-100"
                      } transition-all duration-1000`}
                      style={{
                        boxShadow: "0px 0px 100px #34adbd",
                      }}
                    />
                    {cardBg && (
                      <GatsbyImage
                        alt=""
                        image={cardBg}
                        className={`cards-with-text__card-background w-full h-auto transform ${
                          animation === "Zoom"
                            ? isVisible
                              ? "scale-90"
                              : "scale-100"
                            : ""
                        } ${
                          animation === "Flip"
                            ? "md:absolute md:top-0 md:left-0"
                            : "relative"
                        } transition-all select-none duration-1000`}
                      />
                    )}
                    {cardFg && (
                      <GatsbyImage
                        alt=""
                        image={cardFg}
                        className={`cards-with-text__card-foreground ${
                          animation === "Zoom"
                            ? "w-[150%] h-auto top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                            : "w-full h-full top-0 left-0"
                        } absolute ${
                          animation === "Zoom"
                            ? isVisible
                              ? "scale-125"
                              : "scale-100"
                            : ""
                        } transition-all pointer-events-none select-none duration-1000`}
                        style={{
                          transform:
                            animation === "Flip"
                              ? "rotateY(180deg)"
                              : undefined,
                          position: "absolute",
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface IRowProps {
  rowType: string;
  heading: string;
  body: string;
  cardSide: string;
  cardOffset: string;
  card: {
    background: IGatsbyImageData;
    foreground: IGatsbyImageData;
    foregroundPosition: any; // TODO types
    animation: string;
  }[];
  background: IGatsbyImageData;
}

export const Row: React.FC<IRowProps> = ({
  rowType,
  heading,
  body,
  cardSide,
  cardOffset,
  card,
  background,
}) => {
  const rightAlign = cardSide === "Right";
  const topAlign = cardOffset === "Top";
  const bg = convertToBgImage(getImage(background));

  return (
    <div
      className={`${
        rowType === "Card"
          ? topAlign
            ? "pt-20 lg:pt-32 pb-10 lg:pb-16"
            : "pb-20 lg:pb-32 pt-10 lg:pt-16"
          : "py-0"
      }`}
    >
      {rowType === "Card" ? (
        <Card
          {...{
            heading,
            body,
            rightAlign,
            topAlign,
            background,
            card: card?.[0],
          }}
        />
      ) : (
        <div className="cards-with-text__background w-screen h-small-screen md:h-screen relative z-20">
          <BackgroundImage
            {...bg}
            className="w-full h-full absolute top-0 left-0 z-20"
            style={{ position: "absolute" }}
          />
        </div>
      )}
    </div>
  );
};

interface ICardsWithTextProps {
  blade: {
    heading: string;
    gemstone: IGemstone;
    body: string;
    magicTypes: { name: string; icon: IGatsbyImageData }[];
    background: IGatsbyImageData;
    rows: IRowProps[];
  };
}

const CardsWithText: React.FC<ICardsWithTextProps> = ({
  blade: { heading, gemstone, body, magicTypes, background, rows },
}) => {
  const bg = convertToBgImage(getImage(background));
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className="py-32 md:py-40 relative bg-richBlack text-center md:text-left overflow-hidden"
    >
      {bg ? (
        <BackgroundImage
          {...bg}
          className="w-full h-full absolute top-0 left-0 z-0 bg-contain bg-top bg-norepeat pointer-events-none select-none"
          style={{ position: "absolute" }}
        />
      ) : null}

      <div className="container mx-auto px-4 sm:px-8">
        <div className="relative z-10">
          {heading ? (
            <h2
              className={`${
                inView ? `text-fade-in-glow` : "opacity-0"
              } max-w-lg mx-auto mb-8 md:mb-12 text-3xl md:text-4xl lg:text-5xl font-display uppercase text-white text-center`}
            >
              {heading}
            </h2>
          ) : null}

          <div className="flex items-center justify-center max-w-xs mx-auto">
            <span className="flex-1 h-0.5 mr-4 bg-white bg-opacity-50" />
            <Gemstone {...{ ...gemstone }} />
            <span className="flex-1 h-0.5 ml-4 bg-white bg-opacity-50" />
          </div>

          {body ? (
            <p
              className={`${
                inView ? `text-fade-in-glow__delayed` : "opacity-0"
              } max-w-2xl mt-8 md:mt-12 mx-auto text-lg text-white text-opacity-50 text-center`}
            >
              {body}
            </p>
          ) : null}

          {magicTypes?.length ? (
            <div className="mt-4 md:mt-8">
              <div className="flex items-center justify-center -m-2">
                {magicTypes?.map(({ name, icon }) => {
                  const img = getImage(icon);
                  return (
                    <div className="p-2">
                      {img && (
                        <GatsbyImage
                          image={img}
                          className="w-16 h-auto"
                          alt={name}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {rows?.length > 0 ? (
        <div className="mt-8 md:mt-12">
          {rows?.map((row, key) => (
            <Row {...{ key, ...row }} />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CardsWithText;
