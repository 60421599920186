import React from "react";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { useLightboxState } from "@context";

import "./style.scss";
import { IConfig } from "gatsby-node";
import { useInView } from "react-intersection-observer";

interface IYoutubeVideoProps {
  blade: {
    video: { providerUid: string };
    background: IGatsbyImageData;
    buttonText: string;
  };
  config: IConfig;
}

const YoutubeVideo: React.FC<IYoutubeVideoProps> = ({
  blade: { video, background, buttonText },
  config,
}) => {
  const { setLightboxOpen, setLightboxImages, setLightboxVideo } =
    useLightboxState();

  const frame = convertToBgImage(getImage(config?.brandingVideoFrame));
  const bg = convertToBgImage(getImage(background));
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.1,
    triggerOnce: true,
  });
  return (
    <div
      ref={ref}
      className={` w-full py-32 md:py-40 relative overflow-hidden`}
    >
      {bg ? (
        <BackgroundImage
          {...bg}
          className="w-full h-full absolute top-0 left-0"
          style={{ position: "absolute" }}
        />
      ) : null}

      <aside className="block w-full h-full absolute z-10 top-0 left-0 bg-black bg-opacity-20 pointer-events-none" />

      <div className="container max-w-4xl mx-auto px-4 sm:px-8">
        {frame ? (
          <div
            className={`${
              inView ? "fade-in-opacity-with-blue-glow__faster" : "opacity-0"
            } w-full pt-[44.12%] relative`}
          >
            <BackgroundImage
              {...frame}
              className="w-full h-full absolute bg-contain bg-center bg-norepeat top-0 left-0 z-20 pointer-events-none select-none"
              style={{ position: "absolute" }}
            />
            <iframe
              src={`https://www.youtube.com/embed/${video?.providerUid}?modestbranding=1&rel=0&playsinline=0&allowfullscreen=true`}
              frameBorder="0"
              allowFullScreen={true}
              className="w-[97.2%] h-[97%] absolute top-[1.5%] left-[1.4%] rounded-2xl z-10"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default YoutubeVideo;
