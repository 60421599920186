import React from "react";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image";

import Link from "@components/Link";
import Button from "@components/Button";

import { processPath } from "@utils";
import { useInView } from "react-intersection-observer";

export interface INewsCardProps {
  locale: string;
  slug: string;
  title: string;
  featuredImage: { small?: ImageDataLike; large?: ImageDataLike };
  category: { categoryName: string };
  excerpt: string;
  meta: {
    publishedAt: string;
  };
}

const LargeNewsCard: React.FC<INewsCardProps> = ({
  locale,
  slug,
  title,
  featuredImage,
  category,
  excerpt,
  meta,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  });
  return (
    <div ref={ref} className="">
      <Link
        to={processPath(slug, locale, "News")}
        className={`${
          inView ? "fade-in-opacity-with-blue-glow" : "opacity-0"
        } group flex-1 flex flex-col h-full bg-richBlack`}
      >
        <div className="overflow-hidden">
          {featuredImage && featuredImage.large && (
            <GatsbyImage
              image={getImage(featuredImage?.large)}
              className="w-full h-auto group-hover:scale-110 transition-all duration-300"
              alt=""
            />
          )}
        </div>
        <div className="flex-1 flex flex-col justify-between h-full p-8">
          <div>
            <h2 className="mb-4 text-xl md:text-2xl lg:text-3xl font-display leading-none uppercase text-white">
              {title}
            </h2>
            {excerpt ? (
              <span className="block text-base md:text-lg text-white text-opacity-50">
                {excerpt}
              </span>
            ) : null}
          </div>

          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-6 md:mt-8">
            <span className="text-sm text-white text-opacity-50">
              <span className="text-primary">{category?.categoryName}</span>{" "}
              &bull; {meta?.publishedAt}
            </span>
            <Button
              {...{ clickHandler: () => true }}
              className="mt-4 sm:mt-0 group-hover:px-12 whitespace-nowrap"
            >
              Read more
            </Button>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default LargeNewsCard;
