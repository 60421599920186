import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Default from "@hoc/Default";
import Meta from "@hoc/Meta";
import ComponentResolver from "@components/Resolver";

const Page = ({ pageContext, data }: PageProps<Queries.TypegenPageQuery>) => {
  const config = pageContext?.config;
  
  return (
    <Default {...{ config }}>
      <Meta {...{ seo: data?.page?.seo }} />
      <main>
        <ComponentResolver {...{ ...data?.page, config }} />
      </main>
    </Default>
  );
};

export default Page;

export const query = graphql`
  query Page($id: String, $locale: String) {
    page: datoCmsPage(id: { eq: $id }, locale: { eq: $locale }) {
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      title
      slug
      id
      locale
      blades {
        ... on DatoCmsHero {
          ...Hero
        }
        ... on DatoCmsSimpleHero {
          ...SimpleHero
        }
        ... on DatoCmsYoutubeVideo {
          ...YoutubeVideo
        }
        ... on DatoCmsCardsWithText {
          ...CardsWithText
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsLatestNews {
          ...LatestNews
        }
        ... on DatoCmsMediaGrid {
          ...MediaGrid
        }
        ... on DatoCmsAssetKit {
          ...AssetKits
        }
        ... on DatoCmsFeaturedArticle {
          ...FeaturedArticle
        }
        ... on DatoCmsNewsListing {
          ...NewsListings
        }
        ... on DatoCmsVideoCarousel {
          ...VideoCarousel
        }
        ... on DatoCmsPressKit {
          ...PressKit
        }
        ... on DatoCmsContentHero {
          ...ContentHero
        }
        ... on DatoCmsSupport {
          ...Support
        }
        ... on DatoCmsHighlight {
          ...Highlights
        }
      }
    }
  }
`;
