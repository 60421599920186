import React from "react";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

import { formatLink } from "@utils";

import Button from "@components/Button";

interface IHeroProps {
  blade: {
    background: IGatsbyImageData;
    foregroundImage: IGatsbyImageData;
    showSteamLogo: boolean;
    steamLogoMessage: string;
    buttons: {
      type: string;
      label: string;
      color: string;
      link: string;
      url: string;
    }[];
  };
  config: {
    steamLogo?: {
      url: string;
    };
  };
}

const Hero: React.FC<IHeroProps> = ({
  blade: {
    background,
    foregroundImage,
    showSteamLogo,
    steamLogoMessage,
    buttons,
  },
  config,
}) => {
  const bg = convertToBgImage(getImage(background));
  const fg = getImage(foregroundImage);
  const steamLogo = config?.steamLogo?.url;

  const handleNewsletterScroll = () => {
    alert("Scroll to Newsletter");
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen p-12 md:p-20 bg-black text-white">
      {bg ? (
        <BackgroundImage
          {...bg}
          className="w-full h-full absolute top-0 left-0 z-0"
          style={{ position: "absolute" }}
        />
      ) : null}

      {fg ? (
        <GatsbyImage
          alt=""
          image={fg}
          className="w-full max-w-xs md:max-w-lg select-none"
        />
      ) : null}

      {showSteamLogo ? (
        <span className="flex items-center justify-center mb-8 md:mb-12 font-light text-lg sm:text-xl text-white relative z-10">
          <span>{steamLogoMessage}</span>

          {steamLogo ? (
            <img
              src={steamLogo}
              className="w-32 sm:w-36 h-auto ml-4 select-none"
            />
          ) : null}
        </span>
      ) : null}

      {buttons?.length > 0 ? (
        <div className="flex flex-col md:flex-row items-center justify-center relative z-10">
          {buttons?.map(({ type, label, color, link, url }, index) => {
            const to = formatLink({
              external: type === "External",
              url,
              page: link,
            });

            return (
              <Button
                {...{
                  to,
                  color: color.toLowerCase(),
                  link: true,
                  external: type === "External",
                  className: `${index === 0 ? "ml-0" : "mt-4 md:mt-0 md:ml-4"}`,
                }}
              >
                {label}
              </Button>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default Hero;
