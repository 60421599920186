import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import Link from "@components/Link";
import Button from "@components/Button";

import { processPath } from "@utils";
import { useInView } from "react-intersection-observer";

export interface INewsCardProps {
  locale: string;
  slug: string;
  title: string;
  featuredImage: { small?: ImageDataLike; large?: ImageDataLike };
  category: { categoryName: string };
  meta: {
    publishedAt: string;
  };
}

const SmallNewsCard: React.FC<INewsCardProps> = ({
  locale,
  slug,
  title,
  featuredImage,
  category,
  meta,
}) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  });
  return (
    <Link
      to={processPath(slug, locale, "News")}
      className={`${
        inView ? "fade-in-opacity-with-blue-glow" : "opacity-0"
      } group flex-1 flex items-between h-full bg-richBlack`}
    >
      <div ref={ref} className="w-1/3 h-full flex-0 relative overflow-hidden">
        {featuredImage && featuredImage.small && (
          <GatsbyImage
            image={getImage(featuredImage?.small)}
            className="w-full h-full absolute top-0 left-0 group-hover:scale-110 transition duration-300"
            style={{ position: "absolute" }}
            alt=""
          />
        )}
      </div>
      <div className="flex-1 flex flex-col justify-between h-full p-6">
        <h2 className="mb-6 md:mb-8 text-lg md:text-xl font-display leading-none uppercase text-white">
          {title}
        </h2>

        <div className="flex items-center justify-between">
          <span className="flex flex-col 2xl:flex-row text-sm text-white text-opacity-50">
            <span className="text-primary">{category?.categoryName}</span>
            <span
              className={`2x:inline-block ${
                category?.categoryName ? "2xl:ml-2" : "ml-0"
              }`}
            >
              {category?.categoryName ? (
                <>
                  <span className="hidden 2xl:inline">&bull; </span>{" "}
                </>
              ) : null}
              {meta?.publishedAt}
            </span>
          </span>
          <Button
            {...{
              height: "h-9",
              clickHandler: () => true,
              clipRight: false,
              className: "md:group-hover:pr-10 -mr-6 text-sm whitespace-nowrap",
            }}
          >
            Read more
          </Button>
        </div>
      </div>
    </Link>
  );
};

export default SmallNewsCard;
