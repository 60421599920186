import React, { BaseSyntheticEvent } from "react";

interface ICheckboxProps {
  required: boolean;
  name: string;
  value: boolean;
  onChange: () => void;
  className?: string;
  children: React.ReactNode;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  required,
  name,
  value: checked,
  onChange,
  className,
  children,
}) => {
  const handleChange = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    typeof onChange === "function" && onChange();
  };

  return (
    <div>
      <label
        htmlFor={name}
        className={`inline-flex items-center relative select-none cursor-pointer ${className}`}
        onClick={(e) => handleChange(e)}
      >
        <input
          {...{
            required,
            type: "checkbox",
            name,
            checked,
            className: "absolute w-full h-full opacity-0 pointer-events-none",
            onChange: () => true,
          }}
        />
        <span>
          <span
            className={`flex items-center justify-center h-5 w-5 mr-4 font-bold ${
              checked ? "bg-seaSerpant text-black" : "bg-white text-white"
            } rounded shadow`}
          >
            &#10003;
          </span>
        </span>
        {children && <span>{children}</span>}
      </label>
    </div>
  );
};

export default Checkbox;
