import React from "react";
import BackgroundImage from "gatsby-background-image";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

import { formatLink } from "@utils";

import Button from "@components/Button";
import Gemstone, { IGemstone } from "@components/Gemstone";
import SocialLinks from "@components/SocialLinks";
import { IConfig } from "gatsby-node";

interface IAssetKitsProps {
  blade: {
    heading: string;
    background: IGatsbyImageData;
    gemstone: IGemstone;
    buttons: {
      type: string;
      label: string;
      color: string;
      link: string;
      url: string;
    }[]; // TODO types
  };
  config: IConfig; // TODO types
}

const AssetKits: React.FC<IAssetKitsProps> = ({
  blade: { heading, background, gemstone, buttons },
  config,
}) => {
  const bg = convertToBgImage(getImage(background));
  const { socials } = config;

  return (
    <div className="w-full py-32 md:py-40 relative bg-richBlack text-center text-white overflow-hidden">
      {bg ? (
        <>
          <BackgroundImage
            {...bg}
            className="w-full h-full absolute top-0 left-0"
            style={{ position: "absolute" }}
          />
          <span className="block w-full h-full absolute top-0 left-0 bg-richBlack bg-opacity-50" />
        </>
      ) : null}

      <div className="relative z-10">
        {heading ? (
          <h2 className="mb-8 md:mb-12 text-3xl md:text-4xl lg:text-5xl font-display uppercase">
            {heading}
          </h2>
        ) : null}

        <div className="flex items-center justify-center max-w-xs mx-auto">
          <span className="flex-1 h-0.5 mr-4 bg-white bg-opacity-50" />
          <Gemstone {...{ ...gemstone }} />
          <span className="flex-1 h-0.5 ml-4 bg-white bg-opacity-50" />
        </div>

        <SocialLinks {...{ socials, className: "mt-8 md:mt-12", config }} />

        {buttons?.length > 0 ? (
          <div className="flex flex-col md:flex-row items-center justify-center mt-8 md:mt-12">
            {buttons?.map(({ type, label, color, link, url }, index) => {
              const to = formatLink({
                external: type === "External",
                url,
                page: link,
              });

              return (
                <Button
                  {...{
                    to,
                    color: color.toLowerCase(),
                    link: true,
                    external: type === "External",
                    download: type === "External",
                    className: `${
                      index === 0 ? "ml-0" : "mt-4 md:mt-0 md:ml-4"
                    }`,
                    key: `assetKitBtn-${index}`,
                  }}
                >
                  {label}
                </Button>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AssetKits;
