import Button from "@components/Button";
import Gemstone, { IGemstone } from "@components/Gemstone";
import Paragraph from "@components/Paragraph";
import Title from "@components/Title";
import { formatLink } from "@utils";
import React from "react";
import FAQ from "./components/FAQ";
import FAQList from "./components/FAQList";

import "./styles.scss";

interface ISupportProps {
  blade: {
    title: string;
    gemstone: IGemstone;
    faqTitle: string;
    faqs: {
      question: string;
      answer: string;
    }[];
    needHelpText: string;
    button: any;
    supportItems: {
      title: string;
      description: string;
    }[];
  };
  config: {};
}

const Support: React.FC<ISupportProps> = ({
  blade: {
    title,
    gemstone,
    faqTitle,
    faqs,
    needHelpText,
    button,
    supportItems,
  },
  config,
}) => {
  return (
    <div className="relative w-full py-12 md:py-20 bg-darkJungleGreen text-white ">
      <div className="featured-article__top w-full h-6 lg:h-12 -mb-6 lg:-mb-12 bg-darkJungleGreen z-20 -top-6 -scale-x-100 md:-top-12 absolute" />

      <div className="px-4 sm:px-8 container mx-auto">
        <Title
          {...{
            color: "white",
            className: "mb-6 text-center mx-auto",
            bgGlow: true,
          }}
        >
          {title}
        </Title>
        <div className="flex items-center justify-center max-w-xs mx-auto mb-12">
          <span className="flex-1 h-0.5 mr-4 bg-white bg-opacity-50" />
          <Gemstone {...{ ...gemstone }} />
          <span className="flex-1 h-0.5 ml-4 bg-white bg-opacity-50" />
        </div>
        <div className="flex justify-center items-center gap-6 mb-32 flex-wrap">
          {supportItems.map((item) => (
            <div className="w-full md:w-[48%] lg:w-1/3 cursor-pointer bg-richBlack hover-teal-shadow hover:scale-105 flex flex-col items-center justify-center p-4 lg:p-16 min-h-[250px] lg:min-h-[350px] lg:flex-1 transition-all duration-500">
              <Title
                {...{ type: 6, color: "white", className: "text-center mb-12" }}
              >
                {item.title}
              </Title>
              <Paragraph {...{ className: "text-center", color: "slateGrey" }}>
                {item.description}
              </Paragraph>
            </div>
          ))}
        </div>
        <div className="w-full lg:w-11/12 xl:w-3/5 mx-auto">
          <div className="flex flex-col md:flex-row justify-between md:items-center mb-12">
            <div className="mr-6 relative flex justify-center items-center">
              <div className="relative xl:right-full">
                <Title
                  {...{
                    color: "white",
                    type: 1,
                    underlined: true,
                    className: "",
                    bgGlow: true,
                  }}
                >
                  {faqTitle}
                </Title>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-between md:justify-center w-full md:w-auto mb-6">
              <Paragraph
                {...{
                  color: "slateGrey",
                  className: "mr-4 !text-md md:!text-lg mb-6 md:mb-0",
                }}
              >
                {needHelpText}
              </Paragraph>
              {button.map(({ type, label, color, link, url }, index) => {
                const to = formatLink({
                  external: type === "External",
                  url,
                  page: link,
                });
                return (
                  <Button
                    {...{
                      to,
                      color: color.toLowerCase(),
                      link: true,
                      external: type === "External",
                      className: `!inline-flex w-full md:w-fit`,
                    }}
                  >
                    {label}
                  </Button>
                );
              })}
            </div>
          </div>
          <div>
            <FAQList {...{ faqs }} />
          </div>
        </div>
      </div>
      <div className="featured-article__top w-full h-6 lg:h-12 -mb-6 lg:-mb-12 transform rotate-180 -scale-x-100 relative bg-darkJungleGreen z-20 -bottom-[48px] md:-bottom-[79px]" />
    </div>
  );
};

export default Support;
