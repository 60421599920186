import React from "react";
import PropTypes from "prop-types";
import Hero from "@components/Hero";
import SimpleHero from "@components/SimpleHero";
import YoutubeVideo from "@components/YoutubeVideo";
import CardsWithText from "@components/CardsWithText";
import LatestNews from "@components/LatestNews";
import MediaGrid from "@components/MediaGrid";
import AssetKits from "@components/AssetKits";
import FeaturedArticle from "@components/FeaturedArticle";
import NewsListings from "@components/NewsListings";
import VideoCarousel from "@components/VideoCarousel";
import PressKit from "@components/PressKit";
import ContentHero from "@components/ContentHero";
import Highlights from "@components/Highlights";
import Support from "@components/Support";

// TODO types IMPORTANT

type Blade = {
  __typename: string;
};

export default function ContainerResolver({ blades, locale, config }) {
  const Components = {
    DatoCmsHero: Hero,
    DatoCmsSimpleHero: SimpleHero,
    DatoCmsYoutubeVideo: YoutubeVideo,
    DatoCmsCardsWithText: CardsWithText,
    DatoCmsLatestNews: LatestNews,
    DatoCmsMediaGrid: MediaGrid,
    DatoCmsAssetKit: AssetKits,
    DatoCmsPressKit: PressKit,
    DatoCmsFeaturedArticle: FeaturedArticle,
    DatoCmsNewsListing: NewsListings,
    DatoCmsVideoCarousel: VideoCarousel,
    DatoCmsContentHero: ContentHero,
    DatoCmsHighlight: Highlights,
    DatoCmsSupport: Support,
  };

  return blades.map((blade: Blade, index) => {
    if (Components?.[blade?.__typename]) {
      return React.createElement(Components[blade.__typename], {
        key: index,
        index,
        blade,
        locale,
        config,
      });
    }

    return null;
  });
}

ContainerResolver.propTypes = {
  blades: PropTypes.array,
  locale: PropTypes.string,
};
