import Gemstone from "@components/Gemstone"
import MediumNewsCard from "@components/NewsCards/Medium"
import Paragraph from "@components/Paragraph"
import Title from "@components/Title"
import BackgroundImage from "gatsby-background-image"
import { IConfig } from "gatsby-node"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import React from "react"
import PressKitCard from "./components/PressKitCard"

interface IPressKitProps {
  blade: any
  config: IConfig
}

const PressKit: React.FC<IPressKitProps> = ({
  blade: { background, description, gemstone, heading, pressKitCards },
  config,
}) => {
  const bottomImg = getImage(background)
  return (
    <section className="min-h-[600px] pt-20 w-full relative bg-richBlack">
      <div className="w-full flex-col relative px-4 sm:px-8 container mx-auto">
        <Title
          {...{
            className: "text-center mb-6 mx-auto",
            type: 2,
            color: "white",
            bgGlow: true,
          }}
        >
          {heading}
        </Title>
        <div className="flex items-center justify-center max-w-xs mx-auto mb-6">
          <span className="flex-1 h-0.5 mr-4 bg-white bg-opacity-50" />
          <Gemstone {...{ ...gemstone }} />
          <span className="flex-1 h-0.5 ml-4 bg-white bg-opacity-50" />
        </div>
        <Paragraph
          {...{
            className: "text-center mx-auto max-w-lg opacity-50",
            color: "slateGrey",
          }}
        >
          {description}
        </Paragraph>
      </div>
      <div className="w-full min-h-[500px] relative mt-40">
        <div className="clipped-latest-articles w-full h-12 lg:h-12 absolute bg-richBlack z-20 -top-1" />
        <div className="flex justify-center w-full h-full relative md:absolute md:-top-32 z-30">
          <div className="block md:grid md:grid-cols-2 gap-6 w-full md:w-4/5 lg:w-2/3 px-4 sm:px-8 justify-center container mx-auto">
            {pressKitCards.map((card: any) => (
              <PressKitCard {...{ ...card }} />
            ))}
          </div>
        </div>
        {bottomImg ? (
          <GatsbyImage
            image={bottomImg}
            className="w-full h-full relative bottom-0 "
            style={{ position: "" }}
          />
        ) : null}
      </div>
    </section>
  )
}

export default PressKit
