import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

interface IWithIsVisibleProps {
  children: React.ElementType;
  partialVisibility?: boolean;
  props?: any;
}

const WithIsVisible: React.FC<IWithIsVisibleProps> = ({
  children: Component,
  partialVisibility = false,
  ...props
}) => {
  const [active, setActive] = useState(true);

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setActive(false);
    }
  };

  return (
    <VisibilitySensor
      {...{
        intervalDelay: 500,
        scrollCheck: true,
        partialVisibility,
        onChange: (isVisible: boolean) => handleVisibilityChange(isVisible),
      }}
    >
      {({ isVisible }: { isVisible: boolean }) => (
        <Component {...{ active, isVisible, ...props }} />
      )}
    </VisibilitySensor>
  );
};

export default WithIsVisible;
