import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

import Newsletter from "@components/Newsletter";
import Gemstone, { IGemstone } from "@components/Gemstone";
import LargeNewsCard from "@components/NewsCards/Large";
import SmallNewsCard from "@components/NewsCards/Small";
import { IConfig } from "gatsby-node";

export interface IPostsNode {
  locale: string;
  title: string;
  slug: string;
  excerpt: string;
  category: {
    categoryName: string;
  };
  featuredImage: {
    small: { gatsbyImageData: IGatsbyImageData };
    large: { gatsbyImageData: IGatsbyImageData };
  };
  meta: {
    publishedAt: string;
  };
}
interface ILatestNewsProps {
  blade: {
    showNewsletter: boolean;
    heading: string;
    gemstone: IGemstone;
    body: string;
    showLatest: boolean;
    background: IGatsbyImageData;
    posts: IPostsNode[];
  };
  config: IConfig;
}

const LatestNews: React.FC<ILatestNewsProps> = ({
  blade: {
    showNewsletter,
    heading,
    gemstone,
    body,
    showLatest,
    background,
    posts,
  },
  config,
}) => {
  const bg = convertToBgImage(getImage(background));

  const { latestPosts } = useStaticQuery(graphql`
    query LatestNewsQuery {
      latestPosts: allDatoCmsNews(
        limit: 5
        sort: { fields: meta___publishedAt, order: DESC }
      ) {
        edges {
          node {
            locale
            title
            slug
            excerpt
            category {
              categoryName
            }
            featuredImage {
              small: gatsbyImageData(
                imgixParams: { w: "300", h: "400", fit: "crop" }
              )
              large: gatsbyImageData(
                imgixParams: { w: "1000", h: "600", fit: "crop" }
              )
            }
            meta {
              publishedAt(formatString: "DD MMMM, YYYY")
            }
          }
        }
      }
    }
  `);
  const _posts: IPostsNode[] = showLatest
    ? latestPosts?.edges?.map(({ node }: { node: IPostsNode }) => node)
    : posts;

  return _posts?.length > 0 ? (
    <div className={`${showNewsletter ? "md:-mt-96" : "mt-0"} relative`}>
      {bg ? (
        <>
          <BackgroundImage
            {...bg}
            className={`w-full h-full absolute ${
              showNewsletter ? "top-0" : "top-0" //this was top-48 : top-0 but was causing a visual bug so fixed for now
            } left-0 bg-cover -z-10`}
            style={{ position: "absolute" }}
          />
          <span className="block w-full h-48 absolute top-96 -mt-4 left-0 bg-gradient-to-b from-richBlack to-transparent -z-10" />
        </>
      ) : null}

      {showNewsletter ? (
        <Newsletter {...{ config, className: "-mt-48" }} />
      ) : null}

      <div className={`pt-20 pb-20 md:pb-32`}>
        <div className="container mx-auto px-4 sm:px-8">
          <div className="relative z-10">
            {heading ? (
              <h2 className="mb-8 md:mb-12 text-3xl md:text-4xl lg:text-5xl font-display uppercase text-white text-center">
                {heading}
              </h2>
            ) : null}

            <div className="flex items-center justify-center max-w-xs mx-auto">
              <span className="flex-1 h-0.5 mr-4 bg-white bg-opacity-50" />
              <Gemstone {...{ ...gemstone }} />
              <span className="flex-1 h-0.5 ml-4 bg-white bg-opacity-50" />
            </div>

            {body ? (
              <p className="max-w-xl mt-8 md:mt-12 mx-auto text-lg text-white text-opacity-50 text-center">
                {body}
              </p>
            ) : null}

            <div className="grid grid-cols-1 lg:grid-cols-6 xl:grid-cols-5 gap-6 mt-8 md:mt-12">
              {_posts?.map((post, key) => (
                <div
                  {...{
                    key,
                    className: `${
                      key === 0
                        ? "col-span-1 lg:col-span-6 xl:col-span-3 xl:col-span-3 row-span-1 xl:row-span-4"
                        : "col-span-1 lg:col-span-3 xl:col-span-2 row-span-1"
                    } flex-1 h-full relative `,
                  }}
                >
                  {key === 0 ? (
                    <LargeNewsCard {...{ ...post }} />
                  ) : (
                    <SmallNewsCard {...{ ...post }} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default LatestNews;
