import React from "react";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

import { formatLink, processPath } from "@utils";

import Button from "@components/Button";
import Gemstone, { IGemstone } from "@components/Gemstone";
import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import WithIsVisible from "@components/WithIsVisible";
import { IConfig } from "gatsby-node";
import "./styles.scss";

interface IFeaturedArticleProps {
  blade: {
    __typename: string;
    id: string;
    heading: string;
    featuredArticle: {
      excerpt: string;
      heroImage: {
        gatsbyImageData: IGatsbyImageData;
      };
      id: string;
      meta: {
        firstPublishedAt: string;
      };
      slug: string;
      locale: string;
      title: string;
      category: { categoryName: string };
    };
  };
  config: IConfig;
}

const FeaturedArticle: React.FC<IFeaturedArticleProps> = ({
  blade: {
    featuredArticle: {
      excerpt,
      heroImage,
      title,
      slug,
      category,
      meta,
      locale,
    },
    heading,
  },
  config: { verticalImageFrame },
}) => {
  const bg = convertToBgImage(getImage(verticalImageFrame));
  const cardBg = convertToBgImage(getImage(heroImage));

  return (
    <>
      <div className="featured-article__top w-full h-6 lg:h-12 -mt-6 lg:-mt-12 relative bg-darkJungleGreen z-20" />
      <section className="relative flex flex-col md:flex-row  w-full py-12 lg:min-h-[30rem] lg:max-h-[30rem]  md:py-20 bg-darkJungleGreen text-white">
        <div className="container relative flex flex-col md:flex-row w-full px-4 sm:px-8  mx-auto">
          <div className=" z-10  w-full md:w-[55%] xl:w-[60%]">
            <Title
              {...{
                className: "z-10 mb-2",
                type: 1,
                color: "white",
                underlined: true,
                bgGlow: true,
              }}
            >
              {heading}
            </Title>
            {/* <div className="flex max-w-[200px] z-10 mb-6">
            <span className="flex-1 h-0.5 mr-4 bg-white bg-opacity-50 z-10" />
          </div> */}
            <Title
              {...{
                className: "line-clamp-2 h-12 lg:h-16 z-10 mb-4",
                type: 3,
                color: "white",
              }}
            >
              {title}
            </Title>
            <Paragraph
              {...{
                className:
                  "line-clamp-3 h-18 lg:h-24 text-overflow overflow-hidden mb-4 md:mb-0",
                color: "slateGrey",
              }}
            >
              {excerpt}
            </Paragraph>
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <span className="flex text-sm text-white text-opacity-50 mb-4 sm:mb-0">
                <span className="text-primary">{category?.categoryName}</span>
                <span
                  className={`2x:inline-block ${
                    category?.categoryName ? "ml-2" : "ml-0"
                  }`}
                >
                  {category?.categoryName ? (
                    <>
                      <span className="inline">&bull; </span>{" "}
                    </>
                  ) : null}
                  {meta?.firstPublishedAt}
                </span>
              </span>
              <Button
                {...{
                  link: true,
                  height: "h-9",
                  // clickHandler: () => true,
                  to: processPath(slug, locale, "News"),
                  className: "md:group-hover:pr-10 text-sm whitespace-nowrap",
                }}
              >
                Read more
              </Button>
            </div>
          </div>
          <div className="relative w-full md:w-auto md:h-auto z-40">
            <div className="pt-10 pb-5 md:p-0 mx-auto w-[200px] xs:w-[280px] lg:!w-[380px] xl:!w-[450px] text-center md:absolute left-10 -top-24 lg:-top-28 xl:-top-40">
              <WithIsVisible
                children={({ isVisible }: { isVisible: boolean }) => (
                  <>
                    {bg ? (
                      <div className="block md:relative w-full pt-[142%] scale-110 z-30">
                        <span
                          className={`block w-[80%] h-[80%] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white ${
                            isVisible
                              ? "opacity-100 visible scale-90"
                              : "opacity-0 invisible scale-100"
                          } transition-all duration-1000`}
                          style={{
                            boxShadow: "0px 0px 100px #34adbd",
                          }}
                        />
                        {cardBg && (
                          <>
                            <BackgroundImage
                              {...bg}
                              className="w-full h-full absolute top-0 bg-contain bg-center bg-norepeat left-0 z-40 pointer-events-none select-none"
                              style={{ position: "absolute" }}
                              preserveStackingContext
                            ></BackgroundImage>
                            <div className="absolute top-2 left-4 xs:top-3 xs:left-6 lg:!left-[2rem] lg:!top-[1.125rem] xl:!left-[2.375rem] z-30 w-[83.5%] h-[94.5%]">
                              <BackgroundImage
                                {...cardBg}
                                preserveStackingContext
                                className={` w-full h-full z-30 absolute bg-cover bg-center bg-norepeat md:top-0 md:left-0 transform transition-all select-none duration-1000`}
                                style={{ position: "absolute" }}
                              ></BackgroundImage>
                            </div>
                          </>
                        )}
                      </div>
                    ) : null}
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="featured-article__top w-full h-6 lg:h-12 -mb-6 lg:-mb-12 transform rotate-180 -scale-x-100 relative bg-darkJungleGreen z-20" />
    </>
  );
};

export default FeaturedArticle;
