import React, { useState } from "react";
import {
  getImage,
  GatsbyImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image";

import { useLightboxState } from "@context";

import nextIcon from "@images/icn-next.svg";
import viewIcon from "@images/icn-view.svg";

import Gemstone, { IGemstone } from "@components/Gemstone";
import SVG from "@components/SVG";

import "./style.scss";
import { IConfig } from "gatsby-node";
import FilterMenu from "@components/FilterMenu";
import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import { useInView } from "react-intersection-observer";
import BackgroundGlow from "@components/BackgroundGlow";

interface IMediaGridProps {
  blade: {
    heading: string;
    hasAlternateHeadingView: boolean;
    alternateViewHasFiltering: boolean;
    filterIcon: ImageDataLike;
    gemstone: IGemstone;
    body: string;
    categories: any[]; // TODO types
  };
  config: IConfig;
}

const MediaGrid: React.FC<IMediaGridProps> = ({
  blade: {
    heading,
    hasAlternateHeadingView,
    alternateViewHasFiltering,
    filterIcon,
    gemstone,
    body,
    categories,
  },
  config,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const {
    setLightboxImages,
    lightboxOpen,
    setLightboxOpen,
    setActiveLightboxImage,
  } = useLightboxState();
  // TODO types
  const assets: any[] = categories?.reduce(
    (assets, { primaryCategoryName, subcategories }) => {
      // TODO types
      let images: any[] = [];
      // TODO types
      subcategories?.map(
        ({ images: _images, secondaryCategoryName }: { images: any[] }) => {
          images = [
            ...images,
            ..._images
              .filter(
                (image) =>
                  secondaryCategoryName === selectedFilter ||
                  selectedFilter === null
              )
              .map((image) => ({
                image,
                categoryName: secondaryCategoryName,
              })),
          ];
        }
      );

      return [...assets, ...images];
    },
    []
  );

  const perPage = 6;
  const [currentPage, setCurrentPage] = useState(0);
  let pages = [];

  for (let i = 0; i < assets.length; i++) {
    const _index = Math.floor(i / perPage);

    if (typeof pages[_index] === "undefined") {
      pages[_index] = [assets[i]];
    } else {
      pages[_index].push(assets[i]);
    }
  }

  const handleImageClick = (index: number) => {
    setLightboxImages(assets.map((asset) => asset.image));
    setActiveLightboxImage(index);
    setLightboxOpen(true);
  };

  const handlePagePrev = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1);
  };

  const handlePageNext = () => {
    if (currentPage < pages.length - 1) setCurrentPage(currentPage + 1);
  };

  // const BackgroundGlow: React.FC = () => {
  //   const { ref, inView, entry } = useInView({
  //     /* Optional options */
  //     threshold: 1,
  //     triggerOnce: true,
  //   });
  //   return (
  //     <div
  //       ref={ref}
  //       className={`glow-shadow ${
  //         inView ? "shadow-glow-animation" : ""
  //       } absolute rounded-[50%] left-[calc(50%-40%)] top-[calc(50%-40%)] w-[80%] h-[80%] bg-white -z-10`}
  //     ></div>
  //   );
  // };

  return (
    <>
      <div className="media-grid__top w-full h-6 lg:h-12 -mt-6 lg:-mt-12 relative bg-darkJungleGreen z-20" />
      <div className="bg-darkJungleGreen py-12 md:py-20 overflow-hidden">
        <div className="container mx-auto px-4 sm:px-8">
          {hasAlternateHeadingView ? (
            <div className="flex flex-col lg:flex-row justify-between lg:items-center">
              <div className="justify-self-start items-start">
                {heading && (
                  <Title
                    {...{
                      type: 2,
                      className: "",
                      color: "white",
                      underlined: true,
                      bgGlow: true,
                    }}
                  >
                    {heading}
                  </Title>
                )}
                {body ? (
                  <Paragraph {...{ color: "slateGrey", className: "" }}>
                    {body}
                  </Paragraph>
                ) : null}
              </div>
              <div className="flex justify-end mt-10 lg:mt-0">
                <FilterMenu
                  addFilterClickHandler={(filter: string) => {
                    setSelectedFilter(filter);
                    setCurrentPage(0);
                  }}
                  removeFilterClickHandler={() => {
                    setSelectedFilter(null);
                    setCurrentPage(0);
                  }}
                  filters={categories
                    .map((category) =>
                      category.subcategories.map(
                        (subcategory: any) => subcategory.secondaryCategoryName
                      )
                    )
                    .flat(1)}
                  filterIcon={filterIcon}
                />
              </div>
            </div>
          ) : (
            <div>
              {heading ? (
                <Title
                  color={"white"}
                  className="relative w-fit mx-auto mb-8 md:mb-12 text-3xl md:text-4xl lg:text-5xl font-display uppercase text-white text-center"
                >
                  <BackgroundGlow />
                  {heading}
                </Title>
              ) : null}

              <div className="flex items-center justify-center max-w-xs mx-auto">
                <span className="flex-1 h-0.5 mr-4 bg-white bg-opacity-20" />
                <Gemstone {...{ ...gemstone }} />
                <span className="flex-1 h-0.5 ml-4 bg-white bg-opacity-20" />
              </div>

              {body ? (
                <p className="max-w-xl mt-8 md:mt-12 mx-auto text-lg text-white text-opacity-50 text-center">
                  {body}
                </p>
              ) : null}
            </div>
          )}

          <div className="lg:mt-12 relative">
            <div
              className="flex flex-nowrap -m-8 transition duration-500"
              style={{
                transform: `translate3d(-${currentPage * 100}%, 0, 0)`,
              }}
            >
              {pages?.map((_assets, pageIndex) => (
                <div
                  {...{
                    key: pageIndex,
                    className: `min-w-full w-full flex-0 grid grid-rows-3 lg:grid-rows-2 grid-cols-2 lg:grid-cols-12 gap-3 sm:gap-6 my-10 max-w-7xl mx-auto p-8 ${
                      currentPage === pageIndex
                        ? "opacity-100 visible"
                        : "opacity-0 invisible"
                    } transition-all duration-500`,
                  }}
                >
                  {_assets?.map((asset, assetIndex) => {
                    return (
                      <div
                        {...{
                          key: assetIndex,
                          className:
                            "group col-span-1 lg:col-span-4 cursor-pointer w-full h-full bg-white shadow-md flex flex-col mx-auto flex-nowrap text-white relative overflow-visible",
                          onClick: () =>
                            handleImageClick(pageIndex * perPage + assetIndex),
                        }}
                      >
                        <div className="pt-[60%] relative">
                          <div className="w-full h-full absolute top-0 left-0 overflow-hidden">
                            <GatsbyImage
                              image={getImage(asset.image)}
                              className="w-full h-full absolute top-0 left-0 group-hover:scale-110 transition-all duration-300"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>

            {pages?.length > 1 ? (
              <>
                <button
                  type="button"
                  className={`group flex items-center justify-center p-4 absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-1/2 text-2xl text-white text-center ${
                    lightboxOpen ? "opacity-0 invisible" : "opacity-100"
                  }`}
                  onClick={handlePagePrev}
                >
                  <span className="block transform group-hover:scale-120 transition-all">
                    <SVG
                      src={nextIcon}
                      className="w-16 h-auto transform scale-x-[-1] text-seaSerpant group-hover:text-white transition-all duration-300"
                    />
                  </span>
                </button>
                <button
                  type="button"
                  className={`group flex items-center justify-center p-4 absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2 text-2xl text-white text-center ${
                    lightboxOpen ? "opacity-0 invisible" : "opacity-100"
                  }`}
                  onClick={handlePageNext}
                >
                  <span className="block transform group-hover:scale-120 transition-all">
                    <SVG
                      src={nextIcon}
                      className="w-16 h-auto text-seaSerpant group-hover:text-white transition-all duration-300"
                    />
                  </span>
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="media-grid__top w-full h-6 lg:h-12 -mb-6 lg:-mb-12 transform rotate-180 relative bg-darkJungleGreen z-20" />
    </>
  );
};

export default MediaGrid;
