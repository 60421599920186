import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";

import Link from "@components/Link";
import Button from "@components/Button";

import { processPath } from "@utils";
import Paragraph from "@components/Paragraph";

export interface IPressKitCardProps {
  title: string;
  heroImage: ImageDataLike;
  excerpt: string;
  button: { type: string; url: string; label: string; color: string }[];
}

const PressKitCard: React.FC<IPressKitCardProps> = ({
  title,
  heroImage,
  excerpt,
  button,
}) => {
  return (
    <div className="group flex-1 flex flex-col items-between bg-darkJungleGreen min-h-[524px] mb-6">
      <div className="w-full h-60 flex-0 relative overflow-hidden">
        <GatsbyImage
          image={getImage(heroImage)}
          className="w-full h-full absolute top-0 left-0 group-hover:scale-110 transition duration-300"
          style={{ position: "absolute" }}
          alt=""
        />
      </div>
      <div className="flex-1 flex flex-col justify-between items-center h-[284px] p-6">
        <h2 className=" line-clamp-2 text-overflow mb-6 text-lg md:text-2xl font-display leading-none uppercase text-white">
          {title}
        </h2>
        <Paragraph
          {...{
            className:
              "line-clamp-2 h-12 lg:h-16 mt-0 mb-12 text-center sm:text-left text-slateGrey overflow-hidden",
          }}
        >
          {excerpt}
        </Paragraph>
        <div className="relative">
          <Button
            {...{
              color: button[0].color.toLowerCase(),
              link: true,
              external: button[0].type === "External",
              download: button[0].type === "External",
              className: "text-xs lg:text-sm ",
            }}
          >
            {button[0].label}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PressKitCard;
