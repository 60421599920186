import { FC } from "react";
import React from "react";
import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import nextIcon from "@images/icn-next.svg";
import SVG from "@components/SVG";
import ReactMarkdown from "react-markdown";

export interface IFAQProps {
  question: string;
  answer: string;
  selected: boolean;
  onClick: () => void;
}

const FAQ: FC<IFAQProps> = ({ question, answer, selected, onClick }) => {
  return (
    <div
      className="faq-item py-8 px-6 cursor-pointer transition-all duration-300"
      onClick={onClick}
    >
      <div
        className={`faq-item-header relative z-10 flex justify-between items-center ${
          selected ? "mb-6" : ""
        }`}
      >
        <Title
          {...{
            color: "white",
            className: "!text-2xl sm:!text-3xl md:!text-4xl",
            type: 2,
          }}
        >
          {question}
        </Title>
        <SVG
          src={nextIcon}
          className={`w-16 h-auto transform ${
            selected ? "-rotate-90" : "rotate-90"
          } text-seaSerpant group-hover:text-white transition-all duration-500`}
        />
      </div>

      <div
        className={`${
          selected ? " opacity-100 h-auto" : " opacity-0 h-0"
        } transition-all duration-500 overflow-hidden z-0 faq-item-answer relative`}
      >
        <ReactMarkdown className="text-slateGrey">{answer}</ReactMarkdown>
      </div>
    </div>
  );
};

export default FAQ;
