import { graphql, useStaticQuery } from "gatsby";
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image";
import React, { BaseSyntheticEvent, useState } from "react";
import cx from "classnames";

interface IVideoCarouselItemProps {
  index: number;
  videosLength: number;
  thumbnailImage: ImageDataLike;
  onClick: () => void;
}

const VideoCarouselItem: React.FC<IVideoCarouselItemProps> = ({
  index,
  videosLength,
  thumbnailImage,
  onClick,
}) => {
  const [initialClickPosition, setInitialClickPosition] = useState<any>(null);
  const img = getImage(thumbnailImage);

  const handleVideoMouseDown = (e: any) => {
    //prevent selecting a video if the user is actually swiping

    setInitialClickPosition(e.screenX);
  };

  const handleVideoClick = (e: any) => {
    //prevent selecting a video if the user is actually swiping
    const delta = Math.abs(e.screenX - initialClickPosition);

    if (delta > 10) {
      e.preventDefault();
    } else {
      onClick();
    }
  };

  const { playIcon } = useStaticQuery(graphql`
    query getPlayIcon {
      playIcon: file(relativePath: { eq: "icn-play.png" }) {
        publicURL
        absolutePath
        relativePath
      }
    }
  `);

  const className = cx(
    "flex justify-center max-h-[150px] items-center relative scale-[1.35] shrink-0 group hover:scale-[1.45] hover:z-50 transition-all duration-300 hover:trail-video__shadow !select-none",
    {
      "w-[50%] xs:w-[50%] md:!w-[50%]": videosLength >= 2 && videosLength < 3,
      "w-[50%] xs:w-[50%] md:!w-[35%]": videosLength >= 3 && videosLength < 4,
      "w-[50%] xs:w-[33%] md:!w-[26%]": videosLength >= 4 && videosLength < 6,
      "w-[50%] xs:w-[33%] md:!w-[19%]": videosLength > 6,
    }
  );

  return (
    <div
      id={index === 0 ? "firstVid" : "notFirstVid"}
      className={className}
      onClick={handleVideoClick}
      onMouseDown={handleVideoMouseDown}
    >
      <img
        src={playIcon.publicURL}
        className="will-change-transform !absolute !pointer-events-none !z-20 !overflow-visible block whitespace-nowrap"
        alt=""
      />
      <div
        className={`w-full transition-all duration-300 ${
          index === 0
            ? "clip-video-carousel-item__start"
            : "clip-video-carousel-item"
        } ${
          index === videosLength - 1
            ? "clip-video-carousel-item__end"
            : "clip-video-carousel-item"
        }  !select-none`}
      >
        <GatsbyImage
          alt=""
          image={img}
          Tag="div"
          className="w-full h-full !select-none !pointer-events-none max-h-[150px] !relative !z-0"
        />
      </div>
    </div>
  );
};

export default VideoCarouselItem;
