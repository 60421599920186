import SVG from "@components/SVG"
import Title from "@components/Title"
import { IConfig } from "gatsby-node"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import nextIcon from "@images/icn-next.svg"
import Button from "@components/Button"
import Paragraph from "@components/Paragraph"
import { formatLink, processPath } from "@utils"

interface IHighlightsProps {
  blade: {
    id: string
    __typename: string
    title: string
    cards: {
      description: string
      hasButton: boolean
      image: { gatsbyImageData: ImageDataLike }
      button: any
      nonButtonText: string
      title: string
    }[]
  }
  config: IConfig
}

const getWidth = () => {
  if (typeof document !== `undefined`) {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }
}

function useCurrentWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    // let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      // clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      // timeoutId = setTimeout(() => setWidth(getWidth()), 150);
      setWidth(getWidth())
    }
    // set resize listener
    window.addEventListener("resize", resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return width
}
const Highlights: React.FC<IHighlightsProps> = ({
  blade: { title, cards },
  config,
}) => {
  const currentWidth = useCurrentWidth()
  const _cards = [...cards]
  const perPage =
    currentWidth && currentWidth < 1024 ? (currentWidth < 768 ? 1 : 2) : 3
  const [currentPage, setCurrentPage] = useState(0)
  let pages = []

  for (let i = 0; i < _cards.length; i++) {
    const _index = Math.floor(i / perPage)

    if (typeof pages[_index] === "undefined") {
      pages[_index] = [_cards[i]]
    } else {
      pages[_index].push(_cards[i])
    }
  }
  const handlePagePrev = () => {
    if (currentPage > 0) setCurrentPage(currentPage - 1)
  }

  const handlePageNext = () => {
    if (currentPage < pages.length - 1) setCurrentPage(currentPage + 1)
  }
  return (
    <div className="flex flex-col w-full  py-12 md:py-20 bg-richBlack text-white">
      <div className="container mx-auto px-4 sm:px-8">
        <Title
          {...{ color: "white", className: "", underlined: true, bgGlow: true }}
        >
          {title}
        </Title>

        <div className="lg:mt-12 relative">
          <div
            className="flex -m-8 transition duration-500"
            style={{
              transform: `translate3d(-${currentPage * 100}%, 0, 0)`,
            }}
          >
            {pages?.map((page, pageIndex) => (
              <div
                {...{
                  key: pageIndex,
                  className: `min-w-full w-full items-center flex justify-center items-center gap-6 my-10 max-w-7xl mx-auto p-8 ${
                    currentPage === pageIndex
                      ? "opacity-100 visible"
                      : "opacity-0 invisible"
                  } transition-all duration-500`,
                }}
              >
                {page.map((card) => (
                  <div className=" max-w-[500px] group flex-1 cursor-pointer w-full md:w-[48%] lg:w-1/3 h-full bg-darkJungleGreen shadow-md flex flex-col mx-auto text-white relative overflow-visible">
                    <div className="group  flex flex-col items-between  min-h-[524px]">
                      <div className="w-full h-60 flex-0 relative overflow-hidden">
                        <GatsbyImage
                          image={getImage(card.image)}
                          className="w-full h-full absolute top-0 left-0 group-hover:scale-110 transition duration-300"
                          style={{ position: "absolute" }}
                          alt=""
                        />
                      </div>
                      <div className="flex-1 flex flex-col justify-between items-center h-[284px] p-6">
                        <h2 className=" line-clamp-2 text-overflow mb-6 text-lg md:text-2xl font-display leading-none uppercase text-white">
                          {card.title}
                        </h2>
                        <Paragraph
                          {...{
                            className:
                              "line-clamp-2 h-12 lg:h-16 mt-0 mb-12 text-center sm:text-left text-slateGrey overflow-hidden",
                          }}
                        >
                          {card.description}
                        </Paragraph>
                        <div className="flex items-center justify-center relative">
                          {card.hasButton ? (
                            <>
                              {card.button.map(
                                ({ type, label, color, link, url }, index) => {
                                  const to = formatLink({
                                    external: type === "External",
                                    url,
                                    page: link,
                                  })
                                  return (
                                    <Button
                                      {...{
                                        to,
                                        color: color.toLowerCase(),
                                        link: true,
                                        external: type === "External",
                                        className: `!inline-flex ${
                                          index === 0 ? "mt-8" : "mt-4"
                                        }`,
                                      }}
                                    >
                                      {label}
                                    </Button>
                                  )
                                }
                              )}
                            </>
                          ) : (
                            <Paragraph
                              {...{ color: "primary", className: "uppercase" }}
                            >
                              {card.nonButtonText}
                            </Paragraph>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          {pages?.length > 1 ? (
            <>
              {currentPage !== 0 && (
                <button
                  type="button"
                  className={`group flex items-center justify-center p-4 absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-1/2 text-2xl text-white text-center `}
                  onClick={handlePagePrev}
                >
                  <span className="block transform group-hover:scale-120 transition-all">
                    <SVG
                      src={nextIcon}
                      className="w-16 h-auto transform scale-x-[-1] text-seaSerpant group-hover:text-white transition-all duration-300"
                    />
                  </span>
                </button>
              )}
              {currentPage !== pages.length - 1 && (
                <button
                  type="button"
                  className={`group flex items-center justify-center p-4 absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2 text-2xl text-white text-center `}
                  onClick={handlePageNext}
                >
                  <span className="block transform group-hover:scale-120 transition-all">
                    <SVG
                      src={nextIcon}
                      className="w-16 h-auto text-seaSerpant group-hover:text-white transition-all duration-300"
                    />
                  </span>
                </button>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Highlights
