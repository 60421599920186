import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

export interface IGemstone {
  icon: IGatsbyImageData;
  glow: { red: number; blue: number; green: number; alpha: number };
}

interface IGemstoneProps extends IGemstone {}

const Gemstone: React.FC<IGemstoneProps> = ({ icon, glow }) => {
  const ic = getImage(icon);

  return (
    <div className={`gemstone relative pointer-events-none select-none`}>
      {glow ? (
        <span
          className="w-7 h-7 -mt-px absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 origin rotate-45 animate-pulse-slow pointer-events-none select-none"
          style={{
            boxShadow: `0 0 10px 3px rgba(${glow?.red}, ${glow?.green}, ${glow?.blue}, ${glow?.alpha})`,
          }}
        />
      ) : null}

      {ic && <GatsbyImage alt="" image={ic} className="w-12 h-auto" />}

      {glow ? (
        <span
          className="w-0 h-0 -mt-px absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 origin rotate-45 animate-pulse-slow pointer-events-none select-none mix-blend-luminosity"
          style={{
            boxShadow: `0 0 14px 10px rgba(255, 255, 255, .5)`,
          }}
        />
      ) : null}
    </div>
  );
};

export default Gemstone;
