import React from "react";
import BackgroundImage from "gatsby-background-image";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

import { formatLink } from "@utils";

import Button from "@components/Button";
import Gemstone, { IGemstone } from "@components/Gemstone";
import Title from "@components/Title";

interface ISimpleHeroProps {
  blade: {
    heading: string;
    gemstone: IGemstone;
    background: IGatsbyImageData;
  };
}

const SimpleHero: React.FC<ISimpleHeroProps> = ({
  blade: { gemstone, heading, background },
}) => {
  const bg = convertToBgImage(getImage(background));

  const handleNewsletterScroll = () => {
    alert("Scroll to Newsletter");
  };

  return (
    <section className="relative flex flex-col items-center justify-center w-full h-[40vh] md:h-[50rem] p-12 pt-28 md:p-20 md:pt-28 bg-black text-white overflow-hidden">
      {bg ? (
        <BackgroundImage
          {...bg}
          className="w-full h-full absolute top-0 left-0 z-0"
          style={{ position: "absolute" }}
          preserveStackingContext
        />
      ) : null}
      <div className="container z-10">
        <Title
          {...{
            className: "z-10 mx-auto text-center mb-10",
            type: 1,
            color: "white",
            bgGlow: true,
          }}
        >
          {heading}
        </Title>
        <div className="flex items-center justify-center max-w-xs mx-auto z-10">
          <span className="flex-1 h-0.5 mr-4 bg-white bg-opacity-50 z-10" />
          <Gemstone {...{ ...gemstone }} />
          <span className="flex-1 h-0.5 ml-4 bg-white bg-opacity-50 z-10" />
        </div>
      </div>
    </section>
  );
};

export default SimpleHero;
