import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";

interface IFilterMenuProps {
  filters: any[];
  removeFilterClickHandler: () => void;
  addFilterClickHandler: (categoryName: string) => void;
  filterIcon?: ImageDataLike;
}

const FilterMenu: React.FC<IFilterMenuProps> = ({
  filters,
  addFilterClickHandler,
  removeFilterClickHandler,
  filterIcon = null,
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const filterIc = getImage(filterIcon);

  const _filters = [...filters];
  return (
    <div className="relative md:ml-12 py-6 w-full">
      {filterIc && (
        <GatsbyImage
          image={filterIc}
          alt=""
          className="absolute w-[136px] h-[136px] -left-[51px] top-[calc(50%-68px)] z-10"
          style={{ position: "absolute" }}
        />
      )}
      <div className="filters-menu__inner max-w-[450px] flex-1 flex items-center justify-between h-20 px-12 relative border-t-3 border-b-3 border-charlestonGreen bg-richBlack  backdrop-blur-md">
        <div className="absolute top-0 right-0 h-full w-full filters-menu__gradient-overlay z-10 pointer-events-none" />
        <span className="btn__border block w-20 h-20 absolute top-1/2 -left-3.5 -ml-px transform -translate-x-1/2 -translate-y-full origin-center rotate-39 bg-charlestonGreen pointer-events-none select-none" />
        <span className="btn__border block w-20 h-20 absolute top-1/2 -left-3.5 -ml-px transform -translate-x-1/2 origin-center -rotate-39 bg-charlestonGreen pointer-events-none select-none" />
        <span className="btn__border block w-20 z-20 h-20 absolute top-1/2 -right-3.5 -mr-px transform translate-x-1/2 -translate-y-full origin-center -rotate-39 bg-charlestonGreen pointer-events-none select-none" />
        <span className="btn__border block w-20 z-20 h-20 absolute top-1/2 -right-3.5 -mr-px transform translate-x-1/2 origin-center rotate-39 bg-charlestonGreen pointer-events-none select-none" />

        <div className="flex items-center w-full h-20 ">
          <div className="flex-1 h-full flex items-center justify-between overflow-scroll">
            <div className="h-full flex items-center justify-start pl-8">
              {_filters?.length > 0 ? (
                <ul className="h-full w-full flex items-center">
                  <li
                    onClick={() => {
                      removeFilterClickHandler();
                      setSelectedFilter("");
                    }}
                    className="cursor-pointer h-full items-center flex"
                  >
                    <p className=" text-slateGrey uppercase relative h-full flex items-center px-2 mx-2">
                      All
                      <span
                        className={` w-full h-0.5 absolute top-[97.5%] left-0 bg-white ${
                          "" === selectedFilter ? "opacity-100" : "opacity-0"
                        } group-hover:opacity-100 transition`}
                      />
                    </p>
                  </li>
                  {_filters?.map((filter, key) => {
                    return (
                      <li
                        {...{ key }}
                        onClick={() => {
                          addFilterClickHandler(filter);
                          setSelectedFilter(filter);
                        }}
                        className="cursor-pointer h-full items-center flex group"
                      >
                        <p className=" text-slateGrey uppercase relative h-full flex items-center px-2 mx-2">
                          {filter}

                          <span
                            className={` w-full h-0.5 absolute bottom-0 left-0 bg-white ${
                              filter === selectedFilter
                                ? "opacity-100"
                                : "opacity-0"
                            } group-hover:opacity-100 transition`}
                          />
                        </p>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMenu;
