import React, { BaseSyntheticEvent, useState } from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import addToMailchimp from "gatsby-plugin-mailchimp";

import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import { useInView } from "react-intersection-observer";

const statuses = {
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
  TERMS: "terms",
  LOADING: "loading",
};

interface NewsletterProps {
  className?: string;
  config: {
    newsletterBackground: IGatsbyImageData;
    newsletterHeading: string;
    newsletterBody: string;
  };
}

const Newsletter = ({ className, config }: NewsletterProps) => {
  const {
    newsletterBackground: background,
    newsletterHeading: heading,
    newsletterBody: body,
  } = config;
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0.1,
    triggerOnce: true,
  });
  const bg = convertToBgImage(getImage(background));

  const [email, setEmail] = useState<string>("");
  const [optIn, setOptIn] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(statuses.PENDING);
  const [customMessage, setCustomMessage] = useState<string>("");

  const handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (!optIn) {
      setStatus(statuses.TERMS);
      return false;
    }

    if ([statuses.LOADING, statuses.SUCCESS].indexOf(status) !== -1)
      return false;
    setStatus(statuses.LOADING);

    addToMailchimp(email, {
      EMAIL: email,
    })
      .then((data: any) => {
        const result = data?.result;

        if (data?.msg) {
          setCustomMessage(data.msg);
        }

        if (result === "error" && !data?.msg?.includes("already subscribed")) {
          setStatus(statuses.ERROR);
        } else if (
          result === "success" ||
          data?.msg?.includes("already subscribed")
        ) {
          setStatus(statuses.SUCCESS);
          setCustomMessage("");
        }
      })
      // TODO types
      .catch((e: any) => {
        setStatus(statuses.ERROR);
      });
  };

  return (
    <div
      ref={ref}
      id="beta-signup"
      className={`${
        inView ? "fade-in-opacity-with-blue-glow__faster" : "opacity-0"
      } md:container mx-auto md:px-8 relative z-40 ${className}`}
    >
      <div className="flex flex-col items-center justify-center w-full max-w-3xl mx-auto relative">
        <div className="w-full md:pt-[57%] relative bg-richBlack md:bg-transparent">
          {bg ? (
            <div className="hidden md:block w-full h-full absolute top-0 left-0">
              <BackgroundImage
                {...bg}
                className="w-full h-full absolute top-0 left-0 z-0 bg-contain bg-center bg-norepeat pointer-events-none select-none"
                style={{ position: "absolute" }}
              />
            </div>
          ) : null}

          <div className="flex flex-col items-center justify-center w-full md:h-full p-12 md:absolute md:top-0 md:left-0 text-center z-10">
            {heading ? (
              <h2 className="mb-4 md:mb-8 text-3xl md:text-4xl lg:text-5xl font-display uppercase text-white">
                {heading}
              </h2>
            ) : null}

            {body ? (
              <div className="mb-8 text-white text-opacity-50">{body}</div>
            ) : null}

            {status !== statuses.SUCCESS ? (
              <form onSubmit={handleSubmit}>
                <div className="flex">
                  <input
                    type="email"
                    required
                    placeholder="Email"
                    value={email}
                    onChange={({ currentTarget: { value } }) => setEmail(value)}
                    className="flex-1 h-12 px-8 bg-black text-white text-opacity-50 appearance-none outline-none border-none"
                  />
                  <Button
                    type="submit"
                    className="hidden md:flex flex-0 !px-20 -ml-5"
                  >
                    Sign Up
                  </Button>
                </div>

                <Checkbox
                  required
                  value={optIn}
                  onChange={() => setOptIn(!optIn)}
                  className="max-w-lg mt-4 text-sm text-left text-white text-opacity-50"
                >
                  I opt into email communication from Avalance Studios. For
                  details about how your personal data is managed, see the{" "}
                  <span className="font-semibold underline">
                    Privacy Policy
                  </span>
                  .
                </Checkbox>

                <Button type="submit" className="md:hidden mx-auto mt-8">
                  Sign Up
                </Button>
              </form>
            ) : null}

            <div
              className={`${
                [statuses.SUCCESS, statuses.ERROR, statuses.TERMS].indexOf(
                  status
                ) !== -1
                  ? "block"
                  : "hidden"
              } mt-8 py-2 px-6 uppercase font-bold text-white border-2 border-white`}
            >
              {status === statuses.ERROR
                ? customMessage ||
                  `Something's not quite right. Keep calm and try again`
                : null}
              {status === statuses.TERMS
                ? `You forgot to accept the terms and conditions`
                : null}
              {status === statuses.SUCCESS ? `Sign-up successful` : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
