import Button from "@components/Button"
import Paragraph from "@components/Paragraph"
import Title from "@components/Title"
import { formatLink } from "@utils"
import BackgroundImage from "gatsby-background-image"
import { IConfig } from "gatsby-node"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import React from "react"
import cx from "classnames"

interface IContentHeroProps {
  blade: {
    id: string
    __typename: string
    hasBackgroundImage: boolean
    backgroundImage: { gatsbyImageData: ImageDataLike }
    backgroundColor: { hex: string }
    title: string
    description: string
    isImageLeft: boolean
    foregroundImage: { gatsbyImageData: ImageDataLike }
    foregroundImagePosition: "centered" | "clipped_bottom"
    button: any
    hasJaggedTop?: boolean
    jaggedEdgeBackgroundColor?: string
  }
  config: IConfig
}

const ContentHero: React.FC<IContentHeroProps> = ({
  blade: {
    hasBackgroundImage,
    backgroundColor,
    backgroundImage,
    title,
    description,
    isImageLeft,
    foregroundImage,
    button,
    foregroundImagePosition,
    hasJaggedTop,
    jaggedEdgeBackgroundColor,
  },
  config,
}) => {
  const bgImg = getImage(backgroundImage)
  const bg = convertToBgImage(bgImg)

  const foregroundImageClasses = cx("max-w-md mx-auto", {
    "!absolute left-0 right-0 -bottom-12 md:-bottom-20 w-[50%] md:w-[70%]":
      foregroundImagePosition === "clipped_bottom",
    "md:left-[10%]": isImageLeft,
    "md:right-[10%]": !isImageLeft,
  })
  const foregroundImageContainerClasses = cx(
    "relative w-full max-w-md md:w-[45%] h-full mx-auto",
    {
      "pt-[75.25%] sm:pt-[56.25%] md:pt-[36.25%]":
        foregroundImagePosition === "clipped_bottom",
    }
  )
  return (
    // <div className={`bg-${jaggedEdgeBackgroundColor}`}>
    <div
      className={` relative flex flex-col items-center justify-center w-full py-12 md:py-20 ${
        backgroundColor && !hasBackgroundImage ? `bg-${backgroundColor}` : ""
      } text-white`}
    >
      {hasJaggedTop && (
        <div
          className={`another-jagged-edge bg-${jaggedEdgeBackgroundColor} w-full h-6 lg:h-12 ${
            !hasBackgroundImage
              ? "-top-6 lg:-top-12"
              : " rotate-180 transform top-0"
          } -mb-6 lg:-mb-12 absolute z-20`}
        />
      )}
      <div className={`w-full h-full px-4 sm:px-8 mx-auto container `}>
        {hasBackgroundImage && (
          <>
            <BackgroundImage
              {...bg}
              className={`w-full h-full absolute top-0 left-0 bg-cover -z-10`}
              style={{ position: "absolute" }}
            />
            <div className="bg-black opacity-30 absolute h-full w-full top-0 left-0 -z-10"></div>
          </>
        )}
        <div
          className={`flex flex-col-reverse ${
            isImageLeft ? "md:flex-row" : "md:flex-row-reverse"
          } justify-center w-full items-center`}
        >
          <div className={foregroundImageContainerClasses}>
            <GatsbyImage
              image={getImage(foregroundImage)}
              className={foregroundImageClasses}
              style={{ position: "absolute" }}
            />
          </div>
          <div
            className={`w-full md:w-[55%] ${
              isImageLeft ? "md:pl-12" : "md:pr-12"
            } flex flex-col justify-center mb-6 md:mb-0  `}
          >
            <Title
              {...{
                color: "white",
                type: 2,
                className: "pb-6",
              }}
            >
              {title}
            </Title>
            <Paragraph {...{ color: "slateGrey", className: "" }}>
              {description}
            </Paragraph>
            {button.map(({ type, label, color, link, url }, index) => {
              const to = formatLink({
                external: type === "External",
                url,
                page: link,
              })
              return (
                <Button
                  {...{
                    to,
                    color: color.toLowerCase(),
                    link: true,
                    external: type === "External",
                    className: `!inline-flex ${
                      index === 0 ? "mt-8" : "mt-4"
                    } md:w-fit`,
                  }}
                >
                  {label}
                </Button>
              )
            })}
          </div>
        </div>
      </div>
    </div>

    // </div>
  )
}

export default ContentHero
