import Button from "@components/Button";
import FilterMenu from "@components/FilterMenu";
import { IPostsNode } from "@components/LatestNews";
import SmallNewsCard from "@components/NewsCards/Small";
import Title from "@components/Title";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { IConfig } from "gatsby-node";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import React, { useEffect, useState } from "react";
import "./styles.scss";

interface INewsListingsProps {
  blade: {
    filters: { categoryName: string }[];
    heading: string;
    filterIcon: ImageDataLike;
    background: ImageDataLike;
  };
  config: IConfig;
}

const NewsListings: React.FC<INewsListingsProps> = ({
  blade: { filters, heading, filterIcon, background },
  config,
}) => {
  const [visiblePosts, setVisiblePosts] = useState<number>(8);
  const bg = convertToBgImage(getImage(background));
  const filterIc = getImage(filterIcon);
  const { newsPosts } = useStaticQuery(graphql`
    query NewsListingsQuery {
      newsPosts: allDatoCmsNews(
        sort: { fields: meta___publishedAt, order: DESC }
      ) {
        edges {
          node {
            locale
            title
            slug
            excerpt
            category {
              categoryName
            }
            featuredImage {
              small: gatsbyImageData(
                imgixParams: { w: "300", h: "400", fit: "crop" }
              )
              large: gatsbyImageData(
                imgixParams: { w: "1000", h: "600", fit: "crop" }
              )
            }
            meta {
              publishedAt(formatString: "DD MMMM, YYYY")
            }
          }
        }
      }
    }
  `);

  const rootPosts = newsPosts?.edges?.map(
    ({ node }: { node: IPostsNode }) => node
  );

  const [currentPosts, setCurrentPosts] = useState<IPostsNode[]>(
    newsPosts?.edges?.map(({ node }: { node: IPostsNode }) => node)
  );

  return (
    <section className="relative flex flex-col w-full py-12 pt-40 lg:min-h-[30rem]  md:py-20 md:pt-40 bg-darkJungleGreen text-white z-0">
      <div className=" container px-4 sm:px-8 mx-auto">
        <BackgroundImage
          {...bg}
          className="w-full h-full absolute top-0 left-0"
          style={{ position: "absolute" }}
          preserveStackingContext
        ></BackgroundImage>
        <div>
          <Title
            {...{
              className: "z-10 mb-2",
              type: 1,
              color: "white",
              underlined: true,
              bgGlow: true,
            }}
          >
            {heading}
          </Title>
        </div>
        <div className="w-full md:w-auto pt-40 sm:pt-20 flex-1 grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-y-4 lg:gap-x-20 h-full relative">
          <div className="absolute top-0 sm:relative sm:-top-16 right-[-50px] max-w-[300px]  sm:max-w-[500px] col-span-1 lg:col-span-2 xl:col-span-1">
            <FilterMenu
              filterIcon={filterIcon}
              filters={filters.map(({ categoryName }) => categoryName)}
              removeFilterClickHandler={() => {
                setCurrentPosts(rootPosts);
              }}
              addFilterClickHandler={(selectedFilter) => {
                setCurrentPosts(
                  rootPosts.filter(
                    (post: IPostsNode) =>
                      post.category.categoryName === selectedFilter
                  )
                );
              }}
            />
          </div>
          {currentPosts.map((post, index) => (
            <>
              {index < visiblePosts && (
                <div className="transition-all opacity-100">
                  <SmallNewsCard {...{ ...post }} />
                </div>
              )}
            </>
          ))}

          <div className="flex justify-center items-center mt-20 lg:col-span">
            {currentPosts.length > visiblePosts && (
              <Button
                clickHandler={() => {
                  setVisiblePosts(visiblePosts + 8);
                }}
              >
                Load More
              </Button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsListings;
