import { FC, useState } from "react";
import React from "react";
import Title from "@components/Title";
import Paragraph from "@components/Paragraph";
import FAQ, { IFAQProps } from "./FAQ";

interface IFAQListProps {
  faqs: IFAQProps[];
}

const FAQList: FC<IFAQListProps> = ({ faqs }) => {
  const [currentSelectedIndex, setCurrentSelectedIndex] = useState<
    number | null
  >(null);

  return (
    <>
      {faqs.map((faq, index) => (
        <FAQ
          {...{
            ...faq,
            selected: index === currentSelectedIndex,
            onClick: () => {
              setCurrentSelectedIndex(index);
            },
          }}
        />
      ))}
    </>
  );
};

export default FAQList;
